<template>
  <div class="detalle-abono-prestamo mx-2">
    <v-row>
      <v-col cols="12">
        <v-card v-if="prestamo.gasto != undefined">
          <v-card-title>
            {{ prestamo.gasto }}
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong> Fecha: </strong>
                      {{ moment(prestamo.fecha).format("ll") }}
                      <br />
                      <strong>Observacion: </strong>
                      {{ prestamo.observacion }}
                      <br />
                      <strong>Valor: </strong>
                      {{ valor | currency }}
                      <br />
                      <strong>Abonos: </strong>
                      {{ total_abonos | currency }}
                      <br />
                      <strong>Saldo: </strong>
                      {{ saldo | currency }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Abonos
                    <v-spacer></v-spacer>
                    <v-btn
                      color="black"
                      dark
                      @click="dialog = true"
                      v-if="saldo > 0"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar abono</v-btn
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Fecha</th>
                            <th>Valor</th>
                            <th>Estado</th>
                            <th>Observación</th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in abonos" :key="index">
                            <td>
                              {{ moment(item.fecha).format("ll") }}
                            </td>
                            <td>
                              {{ item.valor | currency }}
                            </td>
                            <td>
                              <v-chip label color="success" v-if="item.estado"
                                >Activo</v-chip
                              >
                              <v-chip label color="error" v-else
                                >Anulado</v-chip
                              >
                            </td>
                            <td>
                              {{ item.observacion }}
                            </td>
                            <td>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    fab
                                    small
                                    color="primary"
                                    class="my-2"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="download_abono(item)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </template>
                                <span>Descargar</span>
                              </v-tooltip>
                            </td>
                            <td>
                              <router-link
                                :to="`/print_abono_prestamo/${item.id}`"
                                target="_blank"
                                style="text-decoration: none"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      fab
                                      color="success"
                                      small
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>mdi-printer</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Imprimir</span>
                                </v-tooltip>
                              </router-link>
                            </td>
                            <td>
                              <v-menu
                                :close-on-content-click="true"
                                :nudge-width="200"
                                offset-x
                                v-if="item.estado"
                                ><template
                                  v-slot:activator="{ on: menu, attrs }"
                                >
                                  <v-tooltip top>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        v-on="{ ...tooltip, ...menu }"
                                        v-bind="attrs"
                                        fab
                                        small
                                        color="error"
                                        class="my-2"
                                      >
                                        <v-icon>mdi-cancel</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Anular</span>
                                  </v-tooltip>
                                </template>
                                <v-card>
                                  <v-card-title>Anular</v-card-title>
                                  <v-card-text>
                                    <div style="font-size: 1.3em">
                                      ¿Estas seguro de anular el abono
                                      <strong>
                                        {{ item.folio | folio }}?
                                      </strong>
                                    </div>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary">Cancelar</v-btn>
                                    <v-btn
                                      @click="anular(item.id)"
                                      :dark="!loading"
                                      :color="!loading ? 'black' : 'gray'"
                                      :loading="loading"
                                      :disabled="loading"
                                      >Anular</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" persistent scrollable max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Agregar Abono</span>
                </v-card-title>
                <v-card-text
                  ><v-row dense>
                    <v-col cols="12" md="6" sm="6">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            outlined
                            rounded
                            dense
                            label="Fecha"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          scrollable
                          v-model="date"
                          locale="es-co"
                          color="primary"
                          first-day-of-week="1"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        :label="`Valor abono: ${$options.filters.currency(
                          abono
                        )}`"
                        type="number"
                        v-model.number="abono"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        rows="4"
                        no-resize
                        v-model="obs"
                        label="Observacion"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        :label="`Efectivo: ${$options.filters.currency(
                          metodos.efectivo
                        )}`"
                        type="number"
                        v-model.number="metodos.efectivo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        :label="`Transferencia: ${$options.filters.currency(
                          metodos.transfer
                        )}`"
                        type="number"
                        v-model.number="metodos.transfer"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        :label="`Datafono: ${$options.filters.currency(
                          metodos.datafono
                        )}`"
                        type="number"
                        v-model.number="metodos.datafono"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancel()" color="primary">
                    Cancelar
                  </v-btn>
                  <v-btn
                    :dark="!loading"
                    :color="!loading ? 'black' : 'gray'"
                    :loading="loading"
                    :disabled="loading"
                    @click="abonar()"
                  >
                    Abonar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "detalleAbonoPrestamo",
  data: () => ({
    prestamo: {},
    loading: false,
    obs: "",
    abono: 0,
    date: moment().format("YYYY-MM-DD"),
    menu: false,
    metodos: {
      efectivo: 0,
      transfer: 0,
      datafono: 0,
    },
    dialog: false,
    abonos: [],
  }),
  methods: {
    loadDetallePrestamo() {
      const body = {
        route: "/detalle_prestamo_desc",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.prestamo = response.data.data;
      });
    },
    loadAbonosPrestamos() {
      const body = {
        route: "/abonos_prestamos_desc",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.abonos = response.data.data;
      });
    },
    abonar() {
      this.metodos.efectivo =
        this.metodos.efectivo == "" ? 0 : parseFloat(this.metodos.efectivo);
      this.metodos.transfer =
        this.metodos.transfer == "" ? 0 : parseFloat(this.metodos.transfer);
      this.metodos.datafono =
        this.metodos.datafono == "" ? 0 : parseFloat(this.metodos.datafono);
      const total_metodos =
        this.metodos.efectivo + this.metodos.transfer + this.metodos.datafono;
      if (this.abono <= this.saldo) {
        if (total_metodos === this.abono) {
          this.loading = true;
          const body = {
            route: "/abonar_prestamo",
            data: {
              abono: {
                centro_id: this.prestamo.centro_id,
                sede_id: this.prestamo.sede_id,
                prestamo_id: this.prestamo.id,
                profesional_id: this.prestamo.profesional_id,
                valor: this.abono,
                saldo: this.saldo,
                efectivo: this.metodos.efectivo,
                transfer: this.metodos.transfer,
                datafono: this.metodos.datafono,
                observacion: this.obs == "" ? null : this.obs,
                fecha: this.date,
              },
            },
          };

          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.cancel()
                this.loadAbonosPrestamos();
              }
            })
            .catch((error) => {
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            })
            .finally(() => (this.loading = false));
        } else {
          let alert = {
            alert: true,
            color: "error",
            text: "El valor de los metodos de pago no concuerda con el valor del abono",
          };
          this.$store.dispatch("setAlert", alert);
        }
      } else {
        let alert = {
          alert: true,
          color: "error",
          text: "El valor del abono no debe ser mayor al saldo restante",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
    download_abono(item) {
      this.loading = true;
      axios({
        url: "/viewPDF_abono_prestamo",
        method: "GET",
        params: {
          id: item.id,
        },
        headers: {
          "access-rec": atob(this.$store.state.access_token.access_token).split(
            "-_-"
          )[0],
          SSREC: this.$store.state.access_token.session_start,
        },
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.id = "fileLink";
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          fileLink.setAttribute(
            "open",
            "abono-prestamo-" + this.prestamo.cedula + ".pdf"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          document.getElementById("fileLink").remove();
        })
        .finally(() => (this.loading = false));
    },
    anular(id) {
      this.loading = true;
      const body = {
        route: "/anular_abono_prestamo",
        data: {
          id: id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.loadAbonosPrestamos();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    cancel() {
      this.dialog = false;
      this.abono = 0;
      this.obs = "";
      this.metodos = {
        efectivo: 0,
        transfer: 0,
        datafono: 0,
      };
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadDetallePrestamo();
    this.loadAbonosPrestamos();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    valor() {
      return parseFloat(this.prestamo.iva) + parseFloat(this.prestamo.valor);
    },
    total_abonos() {
      const abonos_activos = this.abonos.filter((abono) => {
        return abono.estado;
      });
      return abonos_activos.reduce((index, item) => {
        return index + parseFloat(item.valor);
      }, 0);
    },
    saldo() {
      return parseFloat(this.valor) - this.total_abonos;
    },
  },
};
</script>
